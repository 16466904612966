<template lang="">
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12" class="mb-15">
                    <v-row class="pa-0 mx-n1 mt-0 mb-4">
                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0">
                            <div style="display: flex; width:100%; height:100%; justify-content: flex-start;">
                                <hr class="vr"/>
                                <p class="tblName pb-2">Permisos por rol</p>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="py-5 pl-7">
                        <v-col 
                            v-for="rol in itemsRoles"
                            :key="rol.id"
                            cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                        >
                            <div class="card-rol d-flex justify-space-between">
                                <p class="rol-name">
                                    {{rol.display_name}}
                                </p>
                                <div class="d-flex justify-center align-center pr-5">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" elevation="0" class="" fab small color="#1E2245" @click="openModal(rol)">
                                                <v-icon class="outlined_v_icon" color="#FFF">edit</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </div>
                                
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <v-row v-if="dialog" justify="center">
            <v-dialog v-model="dialog" scrollable persistent max-width="600px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card class="borde-card">
                    <div class="card_texto">
                        <h2 class="titleModal my-5">{{ tituloModal }}</h2>
                        <v-row>
                            <v-col  cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-text-field
                                    outlined
                                    label="Buscar"
                                    prepend-inner-icon="mdi-magnify"
                                    v-model="busqueda"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                    
                    
                    <v-card-text class="card_texto" style="height: 80%;">
                        <v-container grid-list-md id="contenedor">
                            <v-row class="pa-0 ma-0">
                                <v-col v-if="loading" cols="12" xs="12" sm="12" md="12" class="pa-5 ma-0 text-center">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </v-col>
                                <template v-else>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <div v-for="item in itemsPermissionsFiltered" :key="item.categoria.nombre">
                                            <div class="categoria">{{item.categoria.displayName}}</div>
                                            <div class="ma-0 ml-3 pa-2">
                                                <div v-for="permiso in item.permisos" class="pa-0 ma-0">
                                                    <v-checkbox
                                                        v-model="permiso.checked"
                                                        :label="permiso.title + ' - ' + permiso.description"
                                                        :value="permiso.checked"
                                                        class="pa-0 ma-0"
                                                    ></v-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </template>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions v-if="!loading" class="d-flex justify-end card_accion" >
                        <button
                            class="btnCerrar"
                            @click="cerrarModal()" 
                            :disabled="isSaving"
                        >
                            Cancelar
                        </button>
                        <v-btn
                            class="btnGuardar"
                            @click="guardar()" 
                            :loading="isSaving"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>
<script>
// Apis
import apiRoles from "@/api/roles";
import apiPermisos from "@/api/permisos";

// Plugins
import Notify from "@/plugins/notify";


export default {
    data() {
        return {
            datosLogin  : null,
            rol         : null,
            itemsPermissions            : [],
            itemsRoles                  : [],
            loadingAutocomplete         : false,
            dialog      : false,
            isSaving    : false,
            loading     : false,
            tituloModal : "Actualizar permisos",
            imagen      : "/static/modal/catalogoSATupdate.svg",
            rolId       : null,
            busqueda    : "",
        }
    },
    computed: {
        itemsPermissionsFiltered(){
            return this.itemsPermissions.filter((p) => {
                return p.categoria.displayName.toLowerCase().indexOf(this.busqueda.toLowerCase()) != -1;
            });
        }
    },
    methods: {
        async openModal(item){
            this.dialog = true;

            this.rolId = item.id;

            let param = {
                rol_id: item.id
            }
            this.loading = true;
            await  apiPermisos.getPermissionsRol(param).then(response => {
                this.loading = false;
                this.itemsPermissions = response.data;
            })
            .catch(err => {
                this.loading = false;
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los permisos del rol.");
            })

        },
        getRoles(rol = null, accion) {
            let param = { is_delete: false, paginate: false, order: "asc" };
            apiRoles.getRoles(param).then((response) => {
                this.itemsRoles = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los roles.");
            });
        },
        async cerrarModal(){
            this.dialog             = false
            this.itemsPermissions   = [];
            this.busqueda           = "";
        },


        async guardar(){

            let permisos = [];

            await this.itemsPermissions.map(async val => {
                await val.permisos.map(val2 => {
                    if(val2.checked == true){
                        permisos.push(val2.name)
                    }
                })
            })

            let parametrosAdd = {
                rol_id : this.rolId,
                permisos : permisos,
                tipo:'todos'
            }

            this.isSaving = true
            await apiPermisos.syncPermissionsRol(parametrosAdd).then( response => {
                this.isSaving = false
                Notify.Success("Operación exitosa", "El registro se guardó satisfactoriamente.");
            })
            .catch(err => {
                this.isSaving = false
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al actualizar los permisos del rol.");
            })
            await this.cerrarModal()
        }
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.getRoles()
    },
}
</script>
<style scoped>
    .card-rol {
        background-color: #fff;
        border-radius: 15px;
        max-width: 700px;
    }

    .rol-name {
        margin: 0;
        padding-top: 35px;
        padding-bottom: 35px;
        padding-left: 20px;
    }
    .categoria{
        font-size: 18px;
        font-weight: 700;
    }
</style>